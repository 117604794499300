/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.menu-spacer {
    flex: 1 1 auto;
}

h1 {
    text-align: center;
} 

mat-card {
    max-width: 800px;
    margin: 2em auto;
    text-align: center;
}
  
mat-form-field {
    display: block;
}

.page-header {
    display: flex;
    flex-flow: row;
    justify-content: right;

    margin: 10px;
}

.items-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 10px;
}

.item-card {
    min-width: 400px;
}